#post-475 {
  flex: 1;
}

body, html {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.sidebar {
  position: relative;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-color: #607D8B;
}

.search-container {
  margin-top: 20px;
  background-color: white;
  padding: 15px;
  position: relative;
}

.search-container input {
  font-weight: bold;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.autocomplete-list {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 9999;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

#root {
  height: 100%;
}

.modal  {
  z-index: 99999999;
}

.modal-dialog  {
  z-index: 99999999;
}

.modal-backdrop {
  z-index: 99999998;
}

#root {
  display: flex;
}

.house-item {
  padding: 15px;
  color: white;
  cursor: pointer;
}

.house-item:hover {
  background-color: #78909C;
  user-select: none;
}

.house-item-active {
  background-color: #78909C;
}

@media only screen and (max-width: 576px) {
  .map-container {
    flex: 0.8;
  }
}
